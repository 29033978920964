import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import { useSearchContext } from "../../SearchContext";
import axios from 'axios';
import LanguageSelect from './languageSelect';
import Logo from '../../images/logos/logo.svg';
import { InputLabel } from '@mui/material';
import { ReactComponent as LoaderButton } from '../../images/loaders/loader_white.svg';

const ForgotPasswordForm = ({ setIsAuthenticated }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { sessionExpired } = useSearchContext();
    const [requestError, setRequestError] = useState("");
    const [email, setEmail] = useState("");
    const [requestSuccessful, setRequestSuccessful] = useState("");
    const token = localStorage.getItem('token');
    const [isLoading, setIsLoading] = useState(false);

    const handleBack = () => {
        navigate('/login');
    };

    const handleRequestResetPassword = async (event) => {
        event.preventDefault();
        setRequestSuccessful("");
        setRequestError("");


        if (!email) {
            setRequestError("Please enter all fields.");
            return;
        }

        try {
            setIsLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}user/request-reset-password/?email=${email}`);

            if (response.status === 200) {
                setIsLoading(false);
                setRequestSuccessful("Password reset link sent to email. Follow the link to reset your password.");
            }
        } catch (error) {
            setIsLoading(false);
            setRequestError("Error resetting password.");

        }
    };

    return (
        <>
            <Box
                component="img"
                sx={{
                    display: { xs: '', md: 'none' },
                    position: 'absolute',
                    top: 0,
                    left: 5,
                    height: '10vh',
                    width: '10vh',
                }}
                alt="Logo"
                src={Logo}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        mb: 2,
                    }}
                >
                    <Typography variant="h2">
                        {t('login.forgot_password')}
                    </Typography>
                </Box>
                <Box component="form" sx={{ width: { xs: '70vw', md: '25vw' } }} onSubmit={handleRequestResetPassword}>
                    <LanguageSelect />
                    {(!token || sessionExpired) && (
                        <>
                            <InputLabel sx={{ fontSize: '10px', color: '#6A6C6F', mt: 2 }}>{t('login.email')}</InputLabel>
                            <TextField
                                margin="none"
                                color="primary"
                                fullWidth
                                id="email"
                                placeholder={t('login.enter_email')}
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                autoFocus
                            />
                        </>
                    )}
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        color="primary"
                        sx={{ mt: 3, mb: 2, padding: '10px', borderRadius: '3px' }}
                    >
                        {isLoading ? <LoaderButton className='spinner' /> : t('login.submit')}
                    </Button>
                    <Box
                        onClick={handleBack}
                        sx={{ mt: "3px", display: "flex", justifyContent: "flex-end", cursor: "pointer", color: '#A8A8A8', textDecoration: 'underline' }}
                    >
                        {t('main_page.back')}
                    </Box>
                    <Typography
                        sx={{
                            mt: 1,
                            visibility: requestError || requestSuccessful ? 'visible' : 'hidden',
                            color: requestError ? 'error.main' : 'green',
                            height: '20px',
                        }}
                    >
                        {requestError || requestSuccessful || ''}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default ForgotPasswordForm;
