import React from 'react';
import { useSearchContext } from '../../SearchContext';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import EnIcon from '../../images/flags/en-icon.png';
import NoIcon from '../../images/flags/no-icon.png';
import SuccessfulTick from '../../images/icons/successful_tick_icon.svg';
import { useTranslation } from "react-i18next";
import { useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import organizationCountryAccess from '../../json/organisationCountryAccess.json';

const CountryAndLanguageSelect = () => {
    const { searchCountry, setSearchCountry, countryMenuOpen, setCountryMenuOpen, setPropertyData, setSelectedBuilding, setAllBuilding } = useSearchContext();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down('md'));
    const userData = JSON.parse(localStorage.getItem('user_data'));

    const userOrganisation = userData?.organization || '';
    const accessibleCountries = organizationCountryAccess[userOrganisation]?.countries || [];

    const userLanguage = localStorage.getItem('language') || 'en';

    const countryOptions = [
        { label: t('login.norway'), value: 'norway', icon: NoIcon },
        { label: t('login.uk'), value: 'uk', icon: EnIcon },
    ];

    const languageOptions = [
        { label: t('login.norwegian'), value: 'no', icon: NoIcon },
        { label: t('login.english'), value: 'en', icon: EnIcon },
    ];

    const handleClose = () => setCountryMenuOpen(false);

    const handleCountryChange = (country) => {
        setPropertyData([]);
        setSelectedBuilding('');
        setAllBuilding([]);
        localStorage.removeItem('previousSearchlat');
        localStorage.removeItem('previousSearchlng');
        setSearchCountry(country);
        localStorage.setItem('searchCountry', country);
        navigate(`/prevent`);
    };

    const handleLanguageChange = (language) => {
        i18next.changeLanguage(language);
        localStorage.setItem('language', language);
    };

    // Combine selected country and language
    const selectedCountry = countryOptions.find((c) => c.value === searchCountry);
    const selectedLanguage = languageOptions.find((l) => l.value === userLanguage);

    const displayValue = (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {selectedCountry && (
                <img
                    src={selectedCountry.icon}
                    alt={selectedCountry.label}
                    width={20}
                    height={20}
                />
            )}
            <Typography sx={{ fontSize: '14px', color: 'inherit' }}>
                {selectedCountry?.label} ({selectedLanguage?.label})
            </Typography>
        </Box>
    );

    return (
        <Box>
            <Select
                variant="outlined"
                value="" // Keep the value empty to avoid auto-selecting items
                displayEmpty
                renderValue={() => displayValue}
                open={countryMenuOpen}
                onClose={handleClose}
                onOpen={() => setCountryMenuOpen(true)}
                sx={{
                    borderRadius: '5px',
                    display: "flex",
                    flexDirection: 'row',
                    '& legend': { display: 'none' },
                    '&:hover': {
                        borderColor: 'var(--Primary-Colors-Color-3, #F2BFC6)',
                    },
                    '&.Mui-focused': {
                        borderColor: `var(--Primary-Colors-Color-3, ${theme.palette.primary.main}) !important`,
                        borderWidth: '1px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                    },
                    border: 0,
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    { border: 0, },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    { border: 0, },
                    '&.Mui-focused': {
                        border: 0,
                    }
                }}
            >
                {/* Header Row */}
                <MenuItem disabled>
                    <Grid container sx={{ borderBottom: '0.5px solid #E9EBEE', justifyContent: 'center', alignItems: 'center', paddingBottom: 1 }}>
                        <Grid item xs={6} sx={{ fontWeight: 'bold', fontSize: '10px' }}>{t('login.country')}</Grid>
                        <Grid item xs={6} sx={{ fontWeight: 'bold', fontSize: '10px' }}>{t('login.language')}</Grid>
                    </Grid>
                </MenuItem>

                {/* Country and Language Items */}
                <Grid container sx={{ p: 0, m: 0 }}>
                    {/* Country Column */}
                    <Grid
                        item
                        xs={6}
                        sx={{
                            borderRight: '0.5px solid #E9EBEE',
                            p: 0,
                        }}
                    >
                        {countryOptions.map((country) => (
                            <MenuItem
                                key={country.value}
                                selected={country.value === searchCountry}
                                onClick={() => handleCountryChange(country.value)}
                                disabled={!accessibleCountries.includes(country.value)}
                                data-testid={`country-${country.value}`}
                                sx={{
                                    justifyContent: 'flex-start',
                                    backgroundColor: country.value === searchCountry ? '#F8F6F4' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: '#F8F6F4 !important',
                                        boxShadow: 'none',
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: '#F8F6F4 !important',
                                        boxShadow: 'none',
                                    },
                                    p: '8px 12px',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: '100%',
                                        borderRadius: '5px',
                                    }}
                                >
                                    {/* Country Icon and Label */}
                                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                        <img src={country.icon} width={20} height={20} alt={country.label} />
                                        {!isSmallerScreen && (
                                            <Typography sx={{ ml: 1 }}>{country.label}</Typography>
                                        )}
                                    </Box>
                                    {/* Tick Icon for Selected Item */}
                                    {country.value === searchCountry && (
                                        <img
                                            src={SuccessfulTick}
                                            alt="successful-tick"
                                            style={{ width: 20, height: 20 }}
                                        />
                                    )}
                                </Box>
                            </MenuItem>
                        ))}
                    </Grid>

                    {/* Language Column */}
                    <Grid
                        item
                        xs={6}
                        sx={{
                            p: 0,
                        }}
                    >
                        {languageOptions.map((language) => (
                            <MenuItem
                                key={language.value}
                                selected={language.value === userLanguage}
                                onClick={() => handleLanguageChange(language.value)}
                                sx={{
                                    justifyContent: 'flex-start',
                                    backgroundColor: language.value === userLanguage ? '#F8F6F4' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: '#F8F6F4 !important',
                                        boxShadow: 'none',
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: '#F8F6F4 !important',
                                        boxShadow: 'none',
                                    },
                                    p: '8px 12px',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: '100%',
                                        borderRadius: '5px',
                                    }}
                                >
                                    {/* Language Label */}
                                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                                        {isSmallerScreen && (
                                            <img src={language.icon} width={20} height={20} alt={language.label} />
                                        )}
                                        {!isSmallerScreen && (
                                            <Typography>{language.label}</Typography>
                                        )}
                                    </Box>
                                    {/* Tick Icon for Selected Item */}
                                    {language.value === userLanguage && (
                                        <img
                                            src={SuccessfulTick}
                                            alt="successful-tick"
                                            style={{ width: 20, height: 20 }}
                                        />
                                    )}
                                </Box>
                            </MenuItem>
                        ))}
                    </Grid>
                </Grid>
            </Select>
        </Box>
    );
};

export default CountryAndLanguageSelect;
