import React, { useState, useEffect } from 'react';
import { Popper, Paper, Typography, Box } from '@mui/material';
import { useSearchContext } from '../../SearchContext';
import axios from 'axios';
import i18next from 'i18next';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CheckIcon from '../../images/icons/check_icon.svg';
import NotSavedBookmark from '../../images/icons/not_saved_bookmark.svg';
import SavedBookmark from '../../images/icons/saved_bookmark.svg';
import HoverBookmark from '../../images/icons/hover_bookmark.svg';

const PropertyAddressPaper = () => {
    const { propertyData, hasBasement, isGroundFloor, experiencedSewageBackup, returnPeriod, riskYear } = useSearchContext();
    const [translatedAddress, setTranslatedAddress] = useState("");
    const [isSavedProperty, setIsSavedProperty] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isHovered, setIsHovered] = useState(false); // Add hover state for bookmark icon
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const { t } = useTranslation();

    const replaceAmpersand = (address) => {
        if (address.includes('&')) {
            return address.replace(/&/g, `${t('main_page.and')}`);
        }
        return address;
    };

    useEffect(() => {
        if (propertyData.lat && propertyData.lon) {
            axios.get(`${process.env.REACT_APP_API_BASE_URL}building-coords/reverse/${propertyData.lat},${propertyData.lon}/${propertyData.country}?language=${i18next.language}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
                .then((response) => {
                    const fullTranslatedAddress = response.data;
                    const cleanedAddress = replaceAmpersand(fullTranslatedAddress.address);
                    setTranslatedAddress(cleanedAddress);
                })
                .catch((error) => {
                    console.log(error);
                    setTranslatedAddress("Address not found");
                });
            axios.get(`${process.env.REACT_APP_API_BASE_URL}user/saved-properties/check/?building_id=${propertyData.building_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
                .then((response) => {
                    setIsSavedProperty(response.data.is_saved);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [propertyData, i18next.language, token, navigate]);

    const toggleSaved = async () => {
        if (!isSavedProperty) {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}user/saved-properties/?building_id=${propertyData.building_id}&experienced_sewage_backup=${Object.keys(experiencedSewageBackup).find(key => experiencedSewageBackup[key])}&has_basement=${Object.keys(hasBasement).find(key => hasBasement[key])}&is_ground_floor=${Object.keys(isGroundFloor).find(key => isGroundFloor[key])}&property_address=${translatedAddress}&return_period=${returnPeriod}&risk_year=${riskYear}`, 
                {}, 
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    }
            })
            .then((response) => {
                if (response.status === 200) {
                    setIsSavedProperty(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        } else {
            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}user/saved-properties/?building_id=${propertyData.building_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    setIsSavedProperty(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }
    };

    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);  // Set the popper's anchor
        setIsHovered(true); // Set hover state to true
    };

    const handleMouseLeave = () => {
        setAnchorEl(null);  // Hide the popper
        setIsHovered(false); // Reset hover state
    };

    const open = Boolean(anchorEl);

    const noSelectionMade = !hasBasement.yes && !isGroundFloor.yes && !experiencedSewageBackup.yes;

    return (
        <Paper
            sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 'auto',
                backgroundColor: '#F8F6F4',
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant='bodySmall' sx={{ mb: 1, color: '#9C8F83' }}>{t('main_page.address')}</Typography>
                <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <img 
                    src={
                        isHovered 
                            ? (isSavedProperty ? NotSavedBookmark : SavedBookmark) 
                            : (isSavedProperty ? SavedBookmark : NotSavedBookmark)
                    }
                    alt='bookmark-icon'
                    style={{ width: 20, height: 20, cursor: 'pointer' }}
                    onClick={toggleSaved}
                />
                    <Popper open={open} anchorEl={anchorEl} placement="top" disablePortal style={{ zIndex: 1300 }}>
                        <Paper sx={{ padding: 1, maxWidth: 180, wordWrap: 'break-word', boxShadow: '0px 0px 23px 0px rgba(0, 0, 0, 0.10)', lineHeight: '13.2px' }}>
                            <Typography variant='bodyTiny'>
                            {isSavedProperty ? t('poppers.undo_save_property') : t('poppers.save_property')}
                            </Typography>
                        </Paper>
                    </Popper>
                </Box>
            </Box>
            <Typography variant='h6'>{translatedAddress}</Typography>
            
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', mt: 1 }}>
                {noSelectionMade ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={CheckIcon} alt='check-icon' style={{ width: 20, height: 20 }} />
                        <Typography variant='bodySmall' sx={{ ml: 1, mr: 2 }}>{t('adaptions_page.no_selection_made')}</Typography>
                    </Box>
                ) : (
                    <>
                        {hasBasement.yes && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src={CheckIcon} alt='check-icon' style={{ width: 20, height: 20 }} />
                                <Typography variant='bodySmall' sx={{ ml: 1, mr: 2 }}>{t('adaptions_page.basement')}</Typography>
                            </Box>
                        )}
                        {isGroundFloor.yes && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src={CheckIcon} alt='check-icon' style={{ width: 20, height: 20 }} />
                                <Typography variant='bodySmall' sx={{ ml: 1, mr: 2 }}>{t('adaptions_page.ground_floor')}</Typography>
                            </Box>
                        )}
                        {experiencedSewageBackup.yes && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <img src={CheckIcon} alt='check-icon' style={{ width: 20, height: 20 }} />
                                <Typography variant='bodySmall' sx={{ ml: 1, mr: 2 }}>{t('adaptions_page.sewage_backup')}</Typography>
                            </Box>
                        )}
                    </>
                )}
            </Box>
        </Paper>
    );
}

export default PropertyAddressPaper;
